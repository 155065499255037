import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { RadioButton } from '../../../../Common/Inputs/RadioButton';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { MandatoryFieldStar } from '../../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { getReferentials } from '../../../../../actions/globalActions';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { IValue } from '../../../../../entities/IPickers';
import { EContextList, EReferentials, IReferencial } from '../../../../../entities/IGlobal';
import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import { StaticDataPicker } from '../../../../Common/Pickers/StaticDataPicker';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

const RadioInputSection = styled.div`
    display: flex;
    label {
        margin-right: 29px;
    }
`;

export const GroupingStep5: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const dispatch = useDispatch();
    const [groupingBankGuaranteesOptions, setGroupingBankGuaranteesOptions] = useState<IReferencial[]>(undefined);
    const [groupingCommitmentOptions, setGroupingCommitmentOptions] = useState<IReferencial[]>(undefined);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
        dispatch<GetReferentials>(getReferentials('', EReferentials.GroupingBankGuarantees, EContextList.EApproval)).then(response => {
            setGroupingBankGuaranteesOptions(response);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.GroupingCommitment, EContextList.EApproval)).then(response => {
            setGroupingCommitmentOptions(response);
        });
    }, []);

    const onBooleanChange = (field: string, value: boolean) => {
        eApprovalContext.modifyData(value, field, props.context, props.stepName, props.stepNumber);
    };

    const onReferentialChange = (field: string, option: IValue) => {
        eApprovalContext.modifyData(option?.data, field, props.context, props.stepName, props.stepNumber);
    };

    const onChangeLegalEntity = (element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'leadCompany', props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis required numberOfLines={3}>
                        <FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.isPlanSign`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isPlanSign === true}
                            onClick={() => onBooleanChange('isPlanSign', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isPlanSign === false}
                            onClick={() => onBooleanChange('isPlanSign', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis required numberOfLines={3}>
                        <FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.isProvision`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isProvision === true}
                            onClick={() => onBooleanChange('isProvision', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isProvision === false}
                            onClick={() => onBooleanChange('isProvision', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis required numberOfLines={3}>
                        <FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.jointVenture`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.jointVenture === true}
                            onClick={() => onBooleanChange('jointVenture', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.jointVenture === false}
                            onClick={() => onBooleanChange('jointVenture', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis required numberOfLines={3}>
                        <FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.isManufacturer`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isManufacturer === true}
                            onClick={() => onBooleanChange('isManufacturer', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isManufacturer === false}
                            onClick={() => onBooleanChange('isManufacturer', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis required numberOfLines={3}>
                        <FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.isPartnerShip`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isPartnerShip === true}
                            onClick={() => onBooleanChange('isPartnerShip', true)}
                        />
                        <RadioButton
                            label={intl.formatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isPartnerShip === false}
                            onClick={() => onBooleanChange('isPartnerShip', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <SelectInput
                    value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingBankGuarantees?.id ?
                        [{
                            key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingBankGuarantees?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingBankGuarantees?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingBankGuarantees
                        }] : undefined}
                    onChange={(value) => onReferentialChange('groupingBankGuarantees', value)}
                    inputLabel={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.groupingBankGuarantees`} />}
                    required
                    options={groupingBankGuaranteesOptions?.map(elem => ({
                        key: elem.id,
                        text: elem.name,
                        data: elem
                    }))}
                    type={EDropDownType.DEFAULT}
                />
            </Row>
            <Row numberOfElements={1} noBorder>
                <SelectInput
                    value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingCommitment?.id ?
                        [{
                            key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingCommitment?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingCommitment?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.groupingCommitment
                        }] : undefined}
                    onChange={(value) => onReferentialChange('groupingCommitment', value)}
                    inputLabel={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.groupingCommitment`} />}
                    required
                    options={groupingCommitmentOptions?.map(elem => ({
                        key: elem.id,
                        text: elem.name,
                        data: elem
                    }))}
                    type={EDropDownType.DEFAULT}
                />
            </Row>
            <Row numberOfElements={1} noBorder>
                <StaticDataPicker
                    label={<FormattedMessage id={`eApproval.wizard.step5.${[EApprovalTypes.Grouping]}.leadCompany`} />}
                    value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.leadCompany?.id ? [{
                        key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.leadCompany?.id,
                        text: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.leadCompany?.name,
                        data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.leadCompany
                    }] : undefined}
                    onSelectElement={onChangeLegalEntity}
                    staticData={(eApprovalContext.data?.[EApprovalTypes.Grouping]?.partnersRelationship?.companyPartners || [])?.map(elem => ({
                        key: elem.id,
                        data: elem,
                        text: elem.name
                    }))}
                    required
                />
            </Row>
        </Wrapper>
    );
};
